<nz-page-header nzTitle="Молитвы">
</nz-page-header>

<div>
  <nz-switch [(ngModel)]="isHsr" nzCheckedChildren="Honkai: Star Rail" nzUnCheckedChildren="Genshin Impact"></nz-switch>
</div>


<ng-container *ngIf="isHsr">
  <div class="d-flex stats">
    <span>Круток: {{hsrTotalCount}}</span>
    <span>Лег: {{hsrLegendaryCount}}</span>
    <span>До софта: {{hsrEarlyRolls}}</span>
    <span>Софт: {{hsrSoftRolls}}</span>
    <span>После софта: {{hsrAfterRolls}}</span>
  </div>

  <nz-tabset>
    <nz-tab nzTitle="Ивентовый банер персонажа">
      <div class="banner">
        <app-wish-table [items]="hsrEventCharacterItems"
                        [rolls]="bannersInfoMap[GachaType.HSREventCharacter].rollCount"
                        bannerName="Ивентовый банер персонажа"></app-wish-table>
      </div>
    </nz-tab>

    <nz-tab nzTitle="Ивентовый банер конусов">
      <div class="banner">
        <app-wish-table [items]="hsrEventWeaponItems" [rolls]="bannersInfoMap[GachaType.HSREventWeapon].rollCount"
                        bannerName="Ивентовый банер конусов"></app-wish-table>
      </div>
    </nz-tab>

    <nz-tab nzTitle="Стандартный банер">
      <div class="banner">
        <app-wish-table [items]="hsrStandardItems" [rolls]="bannersInfoMap[GachaType.HSRStandard].rollCount"
                        bannerName="Стандартный банер"></app-wish-table>
      </div>
    </nz-tab>

    <nz-tab nzTitle="Банер новичка">
      <div class="banner">
        <app-wish-table [items]="hsrNoviceItems" [rolls]="bannersInfoMap[GachaType.HSRNovice].rollCount"
                        bannerName="Банер новчика"></app-wish-table>
      </div>
    </nz-tab>

  </nz-tabset>
</ng-container>


<ng-container *ngIf="!isHsr">
  <div class="d-flex stats">
    <span>Круток: {{genshinTotalCount}}</span>
    <span>Лег: {{genshinLegendaryCount}}</span>
    <span>До софта: {{genshinEarlyRolls}}</span>
    <span>Софт: {{genshinSoftRolls}}</span>
    <span>После софта: {{genshinAfterRolls}}</span>
  </div>

  <nz-tabset>
    <nz-tab nzTitle="Ивентовый банер персонажа">
      <div class="banner">
        <app-wish-table [items]="genshinEventCharacterItems"
                        [rolls]="bannersInfoMap[GachaType.EventCharacter].rollCount"
                        bannerName="Ивентовый банер персонажа"></app-wish-table>
      </div>
    </nz-tab>

    <nz-tab nzTitle="Ивентовый банер оружия">
      <div class="banner">
        <app-wish-table [items]="genshinEventWeaponItems" [rolls]="bannersInfoMap[GachaType.EventWeapon].rollCount"
                        bannerName="Ивентовый банер оружия"></app-wish-table>
      </div>
    </nz-tab>

    <nz-tab nzTitle="Стандартный банер">
      <div class="banner">
        <app-wish-table [items]="genshinStandardItems" [rolls]="bannersInfoMap[GachaType.Standard].rollCount"
                        bannerName="Стандартный банер"></app-wish-table>
      </div>
    </nz-tab>
  </nz-tabset>
</ng-container>
