/* *
 *
 *  (c) 2010-2024 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

import U from '../Utilities.js';
const {
  addEvent,
  getMagnitude,
  normalizeTickInterval,
  timeUnits
} = U;
/* *
 *
 *  Composition
 *
 * */
/* eslint-disable valid-jsdoc */
var DateTimeAxis;
(function (DateTimeAxis) {
  /* *
   *
   *  Declarations
   *
   * */
  /* *
   *
   *  Functions
   *
   * */
  /**
   * Extends axis class with date and time support.
   * @private
   */
  function compose(AxisClass) {
    if (!AxisClass.keepProps.includes('dateTime')) {
      AxisClass.keepProps.push('dateTime');
      const axisProto = AxisClass.prototype;
      axisProto.getTimeTicks = getTimeTicks;
      addEvent(AxisClass, 'afterSetOptions', onAfterSetOptions);
    }
    return AxisClass;
  }
  DateTimeAxis.compose = compose;
  /**
   * Set the tick positions to a time unit that makes sense, for example
   * on the first of each month or on every Monday. Return an array with
   * the time positions. Used in datetime axes as well as for grouping
   * data on a datetime axis.
   *
   * @private
   * @function Highcharts.Axis#getTimeTicks
   * @param {Highcharts.TimeNormalizeObject} normalizedInterval
   * The interval in axis values (ms) and the count.
   * @param {number} min
   * The minimum in axis values.
   * @param {number} max
   * The maximum in axis values.
   */
  function getTimeTicks() {
    return this.chart.time.getTimeTicks.apply(this.chart.time, arguments);
  }
  /**
   * @private
   */
  function onAfterSetOptions() {
    if (this.options.type !== 'datetime') {
      this.dateTime = void 0;
      return;
    }
    if (!this.dateTime) {
      this.dateTime = new Additions(this);
    }
  }
  /* *
   *
   *  Classes
   *
   * */
  class Additions {
    /* *
     *
     *  Constructors
     *
     * */
    constructor(axis) {
      this.axis = axis;
    }
    /* *
     *
     *  Functions
     *
     * */
    /**
     * Get a normalized tick interval for dates. Returns a configuration
     * object with unit range (interval), count and name. Used to prepare
     * data for `getTimeTicks`. Previously this logic was part of
     * getTimeTicks, but as `getTimeTicks` now runs of segments in stock
     * charts, the normalizing logic was extracted in order to prevent it
     * for running over again for each segment having the same interval.
     * #662, #697.
     * @private
     */
    normalizeTimeTickInterval(tickInterval, unitsOption) {
      const units = unitsOption || [[
      // Unit name
      'millisecond',
      // Allowed multiples
      [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ['second', [1, 2, 5, 10, 15, 30]], ['minute', [1, 2, 5, 10, 15, 30]], ['hour', [1, 2, 3, 4, 6, 8, 12]], ['day', [1, 2]], ['week', [1, 2]], ['month', [1, 2, 3, 4, 6]], ['year', null]];
      let unit = units[units.length - 1],
        // Default unit is years
        interval = timeUnits[unit[0]],
        multiples = unit[1],
        i;
      // Loop through the units to find the one that best fits the
      // tickInterval
      for (i = 0; i < units.length; i++) {
        unit = units[i];
        interval = timeUnits[unit[0]];
        multiples = unit[1];
        if (units[i + 1]) {
          // `lessThan` is in the middle between the highest multiple
          // and the next unit.
          const lessThan = (interval * multiples[multiples.length - 1] + timeUnits[units[i + 1][0]]) / 2;
          // Break and keep the current unit
          if (tickInterval <= lessThan) {
            break;
          }
        }
      }
      // Prevent 2.5 years intervals, though 25, 250 etc. are allowed
      if (interval === timeUnits.year && tickInterval < 5 * interval) {
        multiples = [1, 2, 5];
      }
      // Get the count
      const count = normalizeTickInterval(tickInterval / interval, multiples, unit[0] === 'year' ?
      // #1913, #2360
      Math.max(getMagnitude(tickInterval / interval), 1) : 1);
      return {
        unitRange: interval,
        count: count,
        unitName: unit[0]
      };
    }
    /**
     * Get the best date format for a specific X value based on the closest
     * point range on the axis.
     *
     * @private
     */
    getXDateFormat(x, dateTimeLabelFormats) {
      const {
          axis
        } = this,
        time = axis.chart.time;
      return axis.closestPointRange ? time.getDateFormat(axis.closestPointRange, x, axis.options.startOfWeek, dateTimeLabelFormats) ||
      // #2546, 2581
      time.resolveDTLFormat(dateTimeLabelFormats.year).main : time.resolveDTLFormat(dateTimeLabelFormats.day).main;
    }
  }
  DateTimeAxis.Additions = Additions;
})(DateTimeAxis || (DateTimeAxis = {}));
/* *
 *
 *  Default Export
 *
 * */
export default DateTimeAxis;