import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import { NzFlexDirective } from 'ng-zorro-antd/flex';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzTableModule} from 'ng-zorro-antd/table';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {WishesComponent} from './wishes/wishes.component';

import ru from '@angular/common/locales/ru';
import {NgOptimizedImage, registerLocaleData} from '@angular/common';
import {WishTableComponent} from './wishes/wish-table/wish-table.component';
import {RewardsComponent} from './rewards/rewards.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {QueueComponent} from './queue/queue.component';
import {StatsComponent} from './stats/stats.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {WhaleMassComponent} from './whale-mass/whale-mass.component';
import {FormsModule} from '@angular/forms';
import {NZ_I18N, ru_RU} from 'ng-zorro-antd/i18n';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {SharedModule} from './shared/shared.module';
import {NzMessageModule} from 'ng-zorro-antd/message';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzImageModule} from 'ng-zorro-antd/image';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {AccountService} from './services/account/account.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {AdminModule} from './admin/admin.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {ViewerRatingComponent} from './viewer-rating/viewer-rating.component';


registerLocaleData(ru);

@NgModule({
  declarations: [
    AppComponent,
    WishesComponent,
    WishTableComponent,
    RewardsComponent,
    QueueComponent,
    StatsComponent,
    WhaleMassComponent,
    ViewerRatingComponent,
  ],
  bootstrap: [AppComponent],
  imports: [SharedModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    BrowserModule,
    AppRoutingModule,
    NgCircleProgressModule.forRoot(),
    FormsModule,
    NzDividerModule,
    NzMessageModule,
    NzAvatarModule,
    NzEmptyModule,
    NzImageModule,
    NzDropDownModule,
    NzIconModule,
    AdminModule,
    NzLayoutModule,
    NzAffixModule,
    NzDrawerModule,
    NzPageHeaderModule,
    NzTabsModule,
    NzSwitchModule,
    NgOptimizedImage,
    NzTableModule,
    NzCollapseModule, NzFlexDirective], providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [AccountService],
      multi: true,
    },
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    {provide: NZ_I18N, useValue: ru_RU},
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
}


function appConfigFactory(accountService: AccountService) {
  return (): Observable<any> => {
    return accountService.getUserInfo().pipe(take(1));
  };
}
