<nz-page-header nzTitle="Откаты">
</nz-page-header>

<div class="rewards-container">
  <ng-container *ngFor="let item of items">
    <div class="reward">
      <div class="progress-container">
        <circle-progress [animation]="false"
                         [backgroundPadding]="0"
                         [backgroundStrokeWidth]="0"
                         [imageHeight]="56"
                         [imageSrc]="item.imageUrl"
                         [imageWidth]="56"
                         [innerStrokeColor]="'#ff3c3c'"
                         [innerStrokeWidth]="2"
                         [outerStrokeColor]="'#51ab4a'"
                         [outerStrokeWidth]="2"
                         [percent]="item.progress"
                         [radius]="56"
                         [responsive]="true"
                         [showImage]="true"
                         [showInnerStroke]="true"
                         [showUnits]="false"
                         [showZeroOuterStroke]="false"
                         [space]="-2"
                         [subtitle]="item.title"
                         class="reward-progress"
        ></circle-progress>
      </div>
      <div class="reward-title">
        <span class="reward-cooldown">{{item.formattedCooldown}}</span>
      </div>
      <div *ngIf="item.isPaused" class="paused-overlay">
      </div>
      <div>{{item.title}}</div>
    </div>
  </ng-container>
</div>
