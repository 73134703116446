export interface ICooldownRewardModel {
  /**
   * Reward Id
   */
  rewardId: number;

  /**
   * Reward URL
   */
  imageUrl: string;

  /**
   * Cooldown expiration (if reward is on cooldown). ISO string
   */
  expireAt: string | null | undefined;

  /**
   * If reward is paused or not
   */
  isPaused: boolean;

  /**
   * Reward Title
   */
  title: string;

  /**
   * Global cooldown in seconds
   */
  cooldown: number;

  /**
   * Is on cooldown
   */
  isOnCooldown: boolean;
}


/**
 * Represents cooldown reward model
 */
export class CooldownRewardModel {

  /**
   * Reward Id
   */
  public rewardId: number = 0;

  /**
   * Reward URL
   */
  public imageUrl: string = '';

  /**
   * Cooldown expiration (if reward is on cooldown). ISO string
   */
  public expireAt: Date | null = null;

  /**
   * If reward is paused or not
   */
  public isPaused = false;

  /**
   * Reward Title
   */
  public title: string = '';

  /**
   * Global cooldown in seconds
   */
  public cooldown: number = 0;

  /**
   * Current cooldown progress
   */
  public progress: number = 0;

  /**
   * Formatted cooldown string
   */
  public formattedCooldown: string | null = null;

  /**
   * Is reward on cooldown
   */
  public isOnCooldown: boolean = false;


  constructor(dto: ICooldownRewardModel) {
    this.rewardId = dto.rewardId;
    this.imageUrl = dto.imageUrl;
    this.expireAt = dto.expireAt === null || dto.expireAt === undefined ? null : new Date(dto.expireAt);
    this.isPaused = dto.isPaused;
    this.title = dto.title;
    this.cooldown = dto.cooldown;
    this.isOnCooldown = dto.isOnCooldown;
    this.updateCooldown();
  }

  public updateCooldown() {
    const date = new Date();
    if (this.expireAt !== null && this.expireAt <= date) {
      this.expireAt = null;
    }
    this.progress = 100;
    if (this.isPaused) {
      this.formattedCooldown = '||';
      return;
    }

    if (this.isOnCooldown) {
      this.progress = 0;
      this.formattedCooldown = 'Запрос в очереди';
      return;
    } else {
      if (this.expireAt !== null) {
        const diffSeconds = Math.floor((this.expireAt.getTime() - date.getTime()) / 1000);
        const hours = Math.floor(diffSeconds / 3600);
        const minutes = Math.floor((diffSeconds % 3600) / 60);
        const seconds = diffSeconds % 60;
        this.progress = Math.floor((this.cooldown - diffSeconds) * 100 / this.cooldown);
        this.formattedCooldown = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return;
      }
    }
    this.formattedCooldown = 'Награда доступна';

  }

}
