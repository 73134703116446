import {Component, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {SignalRService} from '../services/signal-r.service';
import {CooldownRewardModel, ICooldownRewardModel} from './models/cooldown-reward.model';
import {Subscription, timer} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit, OnDestroy {

  /**
   * Reward items
   */
  public items: CooldownRewardModel[] = [];

  /**
   * Timer subscription
   * @private
   */
  private _tickSubscription: Subscription | undefined;

  /**
   * Sync rewards subscription
   * @private
   */
  private _syncRewardsSubscription: Subscription;


  constructor(private _http: HttpClient, private _signalRService: SignalRService) {
    this._syncRewardsSubscription = this._signalRService.syncRewardsObs
    .subscribe(() => this.refresh());

  }

  ngOnInit(): void {
    this._tickSubscription = timer(0, 1000)
    .subscribe(() => this.tick());
    this.refresh();
  }

  ngOnDestroy(): void {
    if (this._tickSubscription !== undefined) {
      this._tickSubscription.unsubscribe();
    }

    if (this._syncRewardsSubscription !== undefined) {
      this._syncRewardsSubscription.unsubscribe();
    }
  }

  private tick(): void {
    for (const item of this.items) {
      item.updateCooldown();
    }
  }

  private refresh(): void {
    this._http
    .get<ICooldownRewardModel[]>('/api/rewards/cooldowns')
    .pipe(map(x => x.map(i => new CooldownRewardModel(i))))
    .subscribe(x => this.items = x);
  }


}
