import {RedemptionStatus} from '../enums/redemption-status.enum';

/**
 * Represents reward redemption
 */
export interface IRedemptionModel {
  id: number;
  twitchId: string;
  imageUrl: string;
  rewardTitle: string;
  usedByUsername: string;
  usedAt: string;
  updatedAt: string;
  status: RedemptionStatus;
  userInput: string | null;
}

/**
 * Represents reward redemption
 */
export class RedemptionModel implements IRedemptionModel {

  /**
   * Database redemption Id
   */
  public id: number;

  /**
   * Name of user redeemed the reward
   */
  public twitchId: string;

  /**
   * Twitch internal redemption id
   */
  public imageUrl: string;

  /**
   * Reward image url
   */
  public rewardTitle: string;

  /**
   * Name of user redeemed the reward
   */
  public usedByUsername: string;

  /**
   * Time when reward was updated
   */
  public usedAt: string;

  /**
   * Time when rewards was used
   */
  public updatedAt: string;

  /**
   * Reward title
   */
  public status: RedemptionStatus;

  /**
   * Optional user input
   */
  public userInput: string | null;


  constructor(dto: IRedemptionModel) {
    this.id = dto.id;
    this.imageUrl = dto.imageUrl;
    this.rewardTitle = dto.rewardTitle;
    this.status = dto.status;
    this.twitchId = dto.twitchId;
    this.updatedAt = dto.updatedAt;
    this.usedAt = dto.usedAt;
    this.usedByUsername = dto.usedByUsername;
    this.userInput = dto.userInput;
  }
}
