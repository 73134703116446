import { IUserAuthModel } from './services/account/models/user-info.model';

export const Navigation = [
  {
    title: 'Поддержка',
    icon: 'dollar',
    link: '/payments/list',
    isVisible: (user: IUserAuthModel) => true,
  },
  {
    title: 'Билды',
    icon: 'read',
    link: '/builds',
    isVisible: (user: IUserAuthModel) => true
  },
  {
    title: 'Молитвы',
    icon: 'audit',
    link: '/wishes',
    isVisible: (user: IUserAuthModel) => true
  },
  {
    title: 'Откаты',
    icon: 'clock-circle',
    link: '/rewards',
    isVisible: (user: IUserAuthModel) => false
  },
  {
    title: 'Очередь',
    icon: 'team',
    link: '/queue',
    isVisible: (user: IUserAuthModel) => true
  },
  {
    title: 'Статистика',
    icon: 'fund',
    link: '/stats',
    isVisible: (user: IUserAuthModel) => true
  },
  {
    title: 'Дурка',
    icon: 'smile',
    link: '/durka',
    isVisible: (user: IUserAuthModel) => user.userInfo?.isAdmin
  },
  {
    title: 'Переводчики',
    link: '/translators',
    icon: 'translation',
    isVisible: (user: IUserAuthModel) => true,
    children: [
      {
        title: 'PC',
        link: '/translators/pc'
      },
      {
        title: 'Android',
        link: '/translators/android'
      },
      {
        title: 'iOS',
        link: '/translators/ios'
      }
    ]
  },
  {
    title: 'Админка',
    icon: 'lock',
    link: '/admin',
    isVisible: (user: IUserAuthModel) => user.userInfo?.isAdmin,
    children: [
      {
        title: 'Оповещения',
        link: '/admin/alerts'
      },
      {
        title: 'Полоски',
        link: '/admin/donation-goals'
      },
      {
        title: 'Билды',
        link: '/builds/edit/list'
      },
      {
        title: 'Ссылки',
        link: '/admin/links'
      }
    ]
  }

];
