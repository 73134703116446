/**
 * Represents server message stat mode
 */
export interface IMessageStatModel {
  time: string;
  messageCounter: number;
}

/**
 * Model with message count statistics
 */
export class MessageStatModel {
  public time: Date;
  public messageCounter: number;


  constructor(dto: IMessageStatModel) {
    this.time = new Date(dto.time);
    this.messageCounter = dto.messageCounter;
  }
}
