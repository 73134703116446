export enum GachaType {
  None = 0,
  Standard = 200,
  EventCharacter = 301,
  EventWeapon = 302,
  HSRStandard = 1,
  HSREventCharacter = 11,
  HSREventWeapon = 12,
  HSRNovice = 2

}
