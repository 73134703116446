import {Component, Input} from '@angular/core';
import {compareAsc} from 'date-fns';
import {compareNumbers} from '../../shared/helpers/sort.helper';
import {WishModel} from '../models/wish.model';

@Component({
  selector: 'app-wish-table',
  templateUrl: './wish-table.component.html',
  styleUrls: ['./wish-table.component.scss']
})
export class WishTableComponent {

  @Input() public bannerName?: string;
  @Input() public items: WishModel[] = [];
  @Input() public rolls: number = 0;

  protected columnDefinition = [
    { title: 'Название', compare: (a: WishModel, b: WishModel) => a.name?.localeCompare(b?.name ?? '') ?? 0, priority: false, width: '160px' },
    { title: 'Крутка', compare: (a: WishModel, b: WishModel) => compareNumbers(a.rollNumber ?? 0, b.rollNumber ?? 0), width: '50px', priority: false },
    { title: 'Дата', compare: (a: WishModel, b: WishModel) => compareAsc(new Date(a.time ?? ''), new Date(b.time?? '')), width: '90px', priority: false },
  ]

  constructor() {
  }

}
