<nz-page-header nzTitle="Очередь запросов">
</nz-page-header>

<nz-table #table [nzData]="items" [nzFrontPagination]="false" [nzShowPagination]="false">
  <thead>
  <tr>
    <th *ngFor="let column of columnDefinitions" [nzSortFn]="column.compare"
        [nzWidth]="column.width"> {{ column.title }}
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of table.data">
    <td>
      <div class="queue-cell title">
        <img [src]="row.imageUrl">
        <span>{{ row.rewardTitle }}</span>
      </div>
    </td>
    <td>
      <div class="queue-cell">
        <span>{{ row.usedByUsername }}</span>
      </div>
    </td>
    <td>
      <div class="queue-cell">
        <span>{{ RedemptionStatusData[row.status].title }}</span>
      </div>
    </td>
    <td>
      <span [innerHTML]="row.userInput ?? '' | linkify"></span>
    </td>
    <td>
      <div class="queue-cell">
        <span>{{ row.usedAt | date:'mediumTime':'+0300' }}</span>
      </div>
    </td>
    <td>
      <div class="queue-cell">
        <span>{{ row.updatedAt | date:'mediumTime':'+0300' }}</span>
      </div>
  </tr>
  </tbody>
</nz-table>
