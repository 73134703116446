import {Component, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {WishModel} from './models/wish.model';
import {forkJoin, Subscription} from 'rxjs';
import {BannerInfoModel} from './models/banner-info.model';
import {GachaType} from './enums/gacha-type.enum';

@Component({
  selector: 'app-wishes',
  templateUrl: './wishes.component.html',
  styleUrls: ['./wishes.component.scss']
})
export class WishesComponent implements OnInit, OnDestroy {

  public GachaType = GachaType;

  public genshinEventCharacterItems: WishModel[] = [];
  public genshinEventWeaponItems: WishModel[] = [];
  public genshinStandardItems: WishModel[] = [];

  public hsrEventCharacterItems: WishModel[] = [];
  public hsrEventWeaponItems: WishModel[] = [];
  public hsrStandardItems: WishModel[] = [];
  public hsrNoviceItems: WishModel[] = [];

  public genshinTotalCount: number = 0;
  public genshinEarlyRolls: number = 0;
  public genshinSoftRolls: number = 0;
  public genshinAfterRolls: number = 0;
  public genshinLegendaryCount: number = 0;

  public hsrTotalCount: number = 0;
  public hsrEarlyRolls: number = 0;
  public hsrSoftRolls: number = 0;
  public hsrAfterRolls: number = 0;
  public hsrLegendaryCount: number = 0;

  public isHsr = false;

  public bannersInfoMap: { [p: number]: BannerInfoModel } = Object.values(GachaType).reduce((map, current) => ({
    ...map,
    [current]: new BannerInfoModel()
  }), {});

  private readonly _subscription: Subscription;

  constructor(private _http: HttpClient,
              private readonly _activatedRoute: ActivatedRoute) {
    this._subscription =
      this._activatedRoute.data.subscribe(x => {
        this.isHsr = x.isHsr;
      })
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    forkJoin([this._http.get<BannerInfoModel[]>('api/wishes/info'),
      ...['301', '302', '200', '1', '2', '11', '12'].map(x => this._http.get<WishModel[]>(`api/wishes/${x}`))])
    .subscribe(bannerInfo => {
      let items = (bannerInfo[0]
        .map(x => x as BannerInfoModel)
        .map(x => new BannerInfoModel(x.gachaType, x.legendaryCount, x.rollCount, x.earlyCount, x.softCount, x.afterSoftCount, x.averageCount)));
      this.genshinLegendaryCount = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? 0 : current.legendaryCount), 0);
      this.genshinTotalCount = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? 0 : current.rollCount), 0);
      this.genshinEarlyRolls = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? 0 : current.earlyCount), 0);
      this.genshinSoftRolls = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? 0 : current.softCount), 0);
      this.genshinAfterRolls = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? 0 : current.afterSoftCount), 0);

      this.hsrLegendaryCount = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? current.legendaryCount : 0), 0);
      this.hsrTotalCount = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? current.rollCount : 0), 0);
      this.hsrEarlyRolls = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? current.earlyCount : 0), 0);
      this.hsrSoftRolls = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? current.softCount : 0), 0);
      this.hsrAfterRolls = items.reduce((sum: number, current: BannerInfoModel) => sum + (current.isHsr ? current.afterSoftCount : 0), 0);

      this.bannersInfoMap = items.reduce((map: { [id: number]: BannerInfoModel }, current: BannerInfoModel) => ({
        ...map,
        [current.gachaType]: current
      }), {});
      this.genshinEventCharacterItems = bannerInfo[1] as (WishModel[]);
      this.genshinEventWeaponItems = bannerInfo[2] as (WishModel[]);
      this.genshinStandardItems = bannerInfo[3] as (WishModel[]);

      this.hsrStandardItems = bannerInfo[4] as (WishModel[]);
      this.hsrNoviceItems = bannerInfo[5] as (WishModel[]);
      this.hsrEventCharacterItems = bannerInfo[6] as (WishModel[]);
      this.hsrEventWeaponItems = bannerInfo[7] as (WishModel[]);
    });
  }

}
