import {Injectable} from '@angular/core';
import * as signalR from '@aspnet/signalr';
import {HubConnectionState, IReconnectPolicy} from '@aspnet/signalr';
import {Observable, Subject, timer} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private _hubConnection: signalR.HubConnection;
  private _syncRewardsSubject = new Subject<void>();
  private _syncRedemptionsSubject = new Subject<void>();
  constructor() {

    this._hubConnection = new signalR.HubConnectionBuilder()
    .withUrl('ws/lerritay-hub')
    .withAutomaticReconnect(new ReconnectPolicy())
    .build();

    this._hubConnection.start();

    this._hubConnection.on('SyncRewards', () => {
      this._syncRewardsSubject.next();
    });

    this._hubConnection.on('SyncRedemptions', () => {
      this._syncRedemptionsSubject.next();
    });

     timer(0, 1000)
       .subscribe(() =>
       {
         if (this._hubConnection.state === HubConnectionState.Disconnected){
           this._hubConnection.start();
         }
       });

  }

  /**
   * Receive notification when rewards should be updated
   */
  public get syncRewardsObs(): Observable<void> {
    return this._syncRewardsSubject.asObservable();
  }

  /**
   * Receive notification when redemptions should be updated
   */
  public get syncRedemptionsObs(): Observable<void> {
    return this._syncRedemptionsSubject.asObservable();
  }

}


class ReconnectPolicy implements IReconnectPolicy {
  nextRetryDelayInMilliseconds(previousRetryCount: number, elapsedMilliseconds: number): number | null {
    return 5000;
  }

}
