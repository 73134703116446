import {GachaType} from '../enums/gacha-type.enum';

export class BannerInfoModel {
  public gachaType: GachaType = GachaType.None;
  public legendaryCount: number = 0;
  public rollCount: number = 0;
  public earlyCount: number = 0;
  public softCount: number = 0;
  public afterSoftCount: number = 0;
  public averageCount: number = 0;

  /**
   * if banner is realted to Honkai Star Rail
   */
  public get isHsr(): boolean {
    return this.gachaType === GachaType.HSRNovice
      || this.gachaType === GachaType.HSREventCharacter
      || this.gachaType === GachaType.HSREventWeapon
      || this.gachaType === GachaType.HSRStandard;
  }


  constructor(gachaType?: GachaType, legendaryCount?: number, rollCount?: number, earlyCount?: number, softCount?: number, afterSoftCount?: number, averageCount?: number) {
    this.gachaType = gachaType ?? GachaType.None;
    this.legendaryCount = legendaryCount ?? 0;
    this.rollCount = rollCount ?? 0;
    this.earlyCount = earlyCount ?? 0;
    this.softCount = softCount ?? 0;
    this.afterSoftCount = afterSoftCount ?? 0;
    this.averageCount = averageCount ?? 0;
  }
}
