import {Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {IMessageStatModel, MessageStatModel} from './models/message-stat.model';
import {finalize, map} from 'rxjs/operators';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  public Highcharts = Highcharts;

  public ChartOptions: Highcharts.Options =
    {
      chart: {
        type: 'spline'
      },
      title: {
        text: ''
      },
      time: {
        timezoneOffset: -3 * 60,
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Время'
        },
        labels: {
          rotation: -45,
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif'
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Количество сообщений'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormat: 'Флудопоток: <b>{point.y}</b> сообщ./мин.'
      },
      series: []
    };

  public loaded = false;


  constructor(private _http: HttpClient) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  private refresh() {
    this._http.get<IMessageStatModel[]>('api/channels/self/message-stats/daily')
    .pipe(finalize(() => this.loaded = true),
      map(x => x.map(s => new MessageStatModel(s)))
    )
    .subscribe(stats => {
      if (!(this.ChartOptions.xAxis instanceof Array) && this.ChartOptions.xAxis) {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 1);
        this.ChartOptions.xAxis.max = date.getTime();
      }
      if (this.ChartOptions.series === undefined) {
        this.ChartOptions.series = [];
      }
      this.ChartOptions.series[0] = {
        type: 'spline',
        data: stats.map(s => [s.time.getTime(), s.messageCounter])
      };
    });
  }

}
