<router-outlet *ngIf="clearWrapper"></router-outlet>
<nz-layout class="layout" *ngIf="!clearWrapper">
  <nz-header class="header">
    <a class="sidebar-toggler"><i nz-icon nzType="menu" [nzRotate]="(isCollapsed ? 90 : 0)"
                                  (click)="toggleSidebar()"></i></a>
    <ul class="ml-5" nz-menu nzMode="horizontal" nzSelectable="false">
      <li nz-menu-item class="brand-item"><a href="https://twitch.tv/lerritay" target="_blank"><i class="brand-twitch"
                                                                                                  nz-icon
                                                                                                  nzType="lerrit:twitch"></i></a>
      </li>
      <li nz-menu-item class="brand-item"><a href="https://lerr.it/discord" target="_blank"><i class="brand-discord"
                                                                                               nz-icon
                                                                                               nzType="lerrit:discord"></i></a>
      <li nz-menu-item class="brand-item"><a href="https://lerr.it/youtube" target="_blank"><i class="brand-youtube"
                                                                                               nz-icon
                                                                                               nzType="lerrit:youtube"></i></a>
      <li nz-menu-item class="auth-item">
        <ng-container *ngIf="!userAuthInfo.isAuthenticated">
          <a href="/api/account/twitch/login" class="twitch-social"> <i nz-icon nzType="lerrit:twitch-small"></i> Войти
            через Twitch</a>
        </ng-container>
        <ng-container *ngIf="userAuthInfo.isAuthenticated">
          <a style="color:white" nz-dropdown [nzDropdownMenu]="accountMenu" nzTrigger="click"
             [nzClickHide]="true">
            <nz-avatar style="vertical-align: sub" [nzSrc]="userAuthInfo.imageUrl"></nz-avatar>
            {{ userAuthInfo.userInfo?.name }}
          </a>
          <nz-dropdown-menu #accountMenu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item routerLink="/profile">Профиль</li>
              <li nz-menu-divider></li>
              <li nz-menu-item nzDanger (click)="logout()">
                Выйти
              </li>
            </ul>
          </nz-dropdown-menu>
        </ng-container>
      </li>
    </ul>
  </nz-header>

  <nz-layout style="margin-top: 64px">
    <nz-sider *ngIf="!sidebarHidden" nzCollapsible nzWidth="200px" [nzCollapsedWidth]="50" [nzTrigger]="null"
              [(nzCollapsed)]="isCollapsed">
      <ul class="side" nz-menu nzMode="inline">
        <ng-container *ngFor="let navItem of navigation">
          <li *ngIf="!navItem.children && navItem.isVisible(userAuthInfo)"
              class="navlink" nz-menu-item [routerLink]="navItem.link" routerLinkActive #rla="routerLinkActive"
              [nzSelected]="rla.isActive">
            <a [routerLink]="navItem.link" nz-flex>
              <i nz-icon [nzType]="navItem.icon"></i> <span>{{ navItem.title }}</span>
            </a>
          </li>
          <li *ngIf="navItem.children && navItem.isVisible(userAuthInfo)"
              class="navlink" nz-submenu [nzTitle]="navItem.title" [nzIcon]="navItem.icon" routerLinkActive
              #rla="routerLinkActive" [nzOpen]="rla.isActive">
            <ul>
              <li *ngFor="let child of navItem.children" class="navlink small" nz-menu-item [routerLink]="child.link"
                  routerLinkActive #rla="routerLinkActive"
                  [nzSelected]="rla.isActive">
                <a [routerLink]="child.link">
                  <span>{{ child.title }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </nz-sider>
    <nz-layout class="content">
      <nz-content>
        <router-outlet></router-outlet>
        <div class="new-content" [ngClass]="{'hidden': videoPreviewHidden}">
          <nz-card [nzBordered]="true" [nzTitle]="twitchReady ? 'Стрим онлайн!' : 'Новое видео на Youtube-канале'"
                   [nzCover]="coverTemplate"
                   [nzExtra]="extraButtons">
          </nz-card>
          <ng-template #coverTemplate>
            <div [hidden]="twitchReady">
              <div id="player">
              </div>
            </div>
            <div id="twitch-player" [hidden]="!twitchReady">
            </div>
          </ng-template>

          <ng-template #extraButtons>
            <a (click)="toggleDisplayVideo()">{{ videoPreviewHidden ? 'Показать' : 'Скрыть' }}</a>
          </ng-template>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
</nz-layout>


<nz-drawer nzPlacement="left" [nzBodyStyle]="{padding: 0}" nzTitle="Lerritay" [nzWidth]="200"
           [nzVisible]="isCollapsed && sidebarHidden"
           (nzOnClose)="isCollapsed = false">
  <ng-container *nzDrawerContent>
    <ul class="side" nz-menu nzMode="inline">
      <ng-container *ngFor="let navItem of navigation">
        <li *ngIf="!navItem.children && navItem.isVisible(userAuthInfo)"
            class="navlink" nz-menu-item [routerLink]="navItem.link" routerLinkActive #rla="routerLinkActive"
            [nzSelected]="rla.isActive">
          <i nz-icon [nzType]="navItem.icon"></i> <span>{{ navItem.title }}</span>
        </li>
        <li *ngIf="navItem.children && navItem.isVisible(userAuthInfo)"
            class="navlink" nz-submenu [nzTitle]="navItem.title" [nzIcon]="navItem.icon" routerLinkActive
            #rla="routerLinkActive" [nzOpen]="rla.isActive">
          <ul>
            <li *ngFor="let child of navItem.children" class="navlink" nz-menu-item [routerLink]="child.link"
                routerLinkActive #rla="routerLinkActive"
                [nzSelected]="rla.isActive">
              <span>{{ child.title }}</span>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</nz-drawer>


