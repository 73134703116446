import {Component, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {compareAsc} from 'date-fns';
import {SignalRService} from '../services/signal-r.service';
import {Subscription} from 'rxjs';
import {IRedemptionModel, RedemptionModel} from './models/redemption.model';
import {map} from 'rxjs/operators';
import {RedemptionStatusData} from './enums/redemption-status.enum';

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit, OnDestroy {

  /**
   * Reward redemptions
   */
  public items: RedemptionModel[] = [];
  /**
   * Redemption status data
   */
  public RedemptionStatusData = RedemptionStatusData;
  /**
   * SyncRedemptions subscription
   * @private
   */
  private readonly _syncSubscription: Subscription;

  public columnDefinitions = [
    { title: 'Награда', compare: (a: RedemptionModel, b: RedemptionModel) => a.rewardTitle.localeCompare(b.rewardTitle), priority: false, width: '180px' },
    { title: 'Пользователь', compare: (a: RedemptionModel, b: RedemptionModel) => a.usedByUsername.localeCompare(b.usedByUsername), priority: false, width: '110px' },
    { title: 'Статус', compare: (a: RedemptionModel, b: RedemptionModel) => RedemptionStatusData[a.status].title.localeCompare(RedemptionStatusData[b.status].title), priority: false, width: '100px' },
    { title: 'Сообщение', compare: (a: RedemptionModel, b: RedemptionModel) => a.userInput?.localeCompare(b?.userInput ?? '') ?? 0, priority: false, width: '380px' },
    { title: 'Дата заказа', compare: (a: RedemptionModel, b: RedemptionModel) => compareAsc(new Date(a.usedAt), new Date(b.usedAt)), priority: false, width: '100px' },
    { title: 'Дата обновления', compare: (a: RedemptionModel, b: RedemptionModel) => compareAsc(new Date(a.updatedAt), new Date(b.updatedAt)), priority: false, width: '100px' },
  ]

  constructor(private _httpClient: HttpClient, private _signalR: SignalRService) {
    this
      ._syncSubscription = this._signalR.syncRedemptionsObs.subscribe(() => this.refresh());
  }

  public ngOnInit(): void {
    this.refresh();
  }

  public ngOnDestroy(): void {
    if (this._syncSubscription !== undefined) {
      this._syncSubscription.unsubscribe();
    }
  }

  /**
   * Refresh items
   */
  public refresh(): void {
    this._httpClient
    .get<IRedemptionModel[]>('/api/rewards/redemptions')
    .pipe(map(x => x.map(item => new RedemptionModel(item))))
    .subscribe(items => this.items = items);
  }

}
