/**
 * Represents possible values for redemption status
 */
export enum RedemptionStatus {

  /**
   * Status unknown (possible a twitch error). Normally status not used
   */
  Unknown = 0,

  /**
   * Redemption is pending in queue
   */
  Unfulfilled = 1,

  /**
   * Redemption is accepted, mint taken
   */
  Fulfilled = 2,

  /**
   * Redemption is cancelled
   */
  Cancelled = 3
}

/**
 * Redemption status data for UI
 */
export const RedemptionStatusData: { [key: number]: { title: string } } = {

  [RedemptionStatus.Unknown]: {
    title: 'Ошибка',
  },

  [RedemptionStatus.Unfulfilled]: {
    title: 'В очереди'
  },
  [RedemptionStatus.Fulfilled]: {
    title: 'Бумага принята'
  },
  [RedemptionStatus.Cancelled]: {
    title: 'Вернули бумагу'
  }
};
