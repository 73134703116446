<h3 class="lerritay-header">{{ bannerName }} ({{ rolls }})</h3>


<nz-table #table [nzData]="items" [nzFrontPagination]="false" [nzShowPagination]="false">
  <thead>
  <tr>
    <th *ngFor="let column of columnDefinition" [nzSortFn]="column.compare" [nzWidth]="column.width"> {{column.title}}</th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of table.data">
      <td>{{ data.name }}</td>
      <td>{{ data.rollNumber }}</td>
      <td>{{ data.time | date:'mediumDate':'+0300' }}</td>
    </tr>
</tbody>
</nz-table>
