import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WishesComponent} from './wishes/wishes.component';
import {RewardsComponent} from './rewards/rewards.component';
import {QueueComponent} from './queue/queue.component';
import {StatsComponent} from './stats/stats.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'payments/list'
  },
  {
    path: 'wishes',
    children: [
      {
        path: '',
        pathMatch: 'full',
        data: {isHsr: false},
        component: WishesComponent
      },
      {
        path: 'hsr',
        pathMatch: 'full',
        data: {isHsr: true},
        component: WishesComponent
      },

    ]
  },
  {
    path: 'rewards',
    component: RewardsComponent
  },
  {
    path: 'queue',
    component: QueueComponent
  },
  {
    path: 'stats',
    component: StatsComponent
  },
  {
    path: 'builds',
    loadChildren: () => import('./builds/builds.module').then(x => x.BuildsModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then(x => x.PaymentsModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(x => x.ProfileModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(x => x.AdminModule)
  },
  {
    path: 'durka',
    loadChildren: () => import('./honkai-stats/honkai-stats.module').then(x => x.HonkaiStatsModule)
  },
  {
    path: 'translators',
    loadChildren: () => import('./translators/translators.module').then(x => x.TranslatorsModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/routes').then(x => x.BlogRoutes)
  },
  {
    path: '**',
    redirectTo: 'builds'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
