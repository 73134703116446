import * as Highcharts from 'highcharts';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {AccountService} from './services/account/account.service';
import {IUserAuthModel} from './services/account/models/user-info.model';
import {Navigation} from './navigation';

declare let gtag: Function;
declare let YT: any;
declare let Twitch: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  public static readonly version = '1.0.0';
  public clearWrapper = false;
  public infoWasShown = false;
  public snowEnabled = true;
  public userAuthInfo: IUserAuthModel = {isAuthenticated: false};
  public isCollapsed = false;
  public sidebarHidden = false;
  public videoPreviewHidden = false;
  public navigation = Navigation;
  public twitchReady = false;

  private _player: any;
  private _twitchPlayer: any;

  constructor(private _router: Router,
              private _titleService: Title,
              private _accountService: AccountService) {

    const rnd = (Math.floor(Math.random() * 4)) + 1;
    document.body.style.setProperty('background-image', `url('/assets/img/bgr/${rnd}.webp')`);

    this._accountService.getUserInfo()
      .subscribe(ui => {
        this.userAuthInfo = ui;
      });

    /**
     * Charts
     */

    Highcharts.setOptions({
      lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        rangeSelectorFrom: 'С',
        rangeSelectorTo: 'По',
        rangeSelectorZoom: 'Период',
        downloadPNG: 'Скачать PNG',
        downloadJPEG: 'Скачать JPEG',
        downloadPDF: 'Скачать PDF',
        downloadSVG: 'Скачать SVG',
        printChart: 'Напечатать график',
      },

    });


    if (localStorage.getItem('snowEnabled') === null) {
      this.snowEnabled = true;
      localStorage.setItem(this.snowEnabled ? 'true' : 'false', 'snowEnabled');
    } else {
      this.snowEnabled = localStorage.getItem('snowEnabled') == 'true';
    }

    this._router.events.subscribe((evt: any) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      this.clearWrapper = evt.urlAfterRedirects.startsWith('/widgets/');
      if (this.clearWrapper) {
        document.body.style.backgroundImage = 'none';
        document.body.style.overflow = 'hidden';
      }

      window.scrollTo(0, 0);
      if (environment.production) {
        gtag('config', 'mxrSA6qtvO0', {
          'page_path': evt.urlAfterRedirects,
          'page_title': `${this._titleService.getTitle()} - ${evt.urlAfterRedirects}`,
        });
      }
    });

  }

  ngOnInit(): void {
    this.sidebarHidden = window.innerWidth < 600;
  }

  ngAfterViewInit(): void {
    this._player = new YT.Player('player', {
      width: '100%',
      height: '250px',
      events: {
        onReady: function onPlayerReady(event: any) {
          event.target.loadPlaylist({list: 'UULFATcmWRkRHaB5e7qP2LRoRQ'});
          window.setTimeout(() => {
            event.target.mute();
            event.target.playVideo();
          }, 1000);
        }
      }
    });

    this._twitchPlayer = new Twitch.Player('twitch-player', {
      width: '100%',
      height: '250px',
      channel: 'lerritay',
      parent: [location.hostname]
    });

    this._twitchPlayer.addEventListener(Twitch.Player.ONLINE, () => {
      console.log('CHANNEL ONLINE');
      this.twitchReady = true;
      this._twitchPlayer.setVolume(0);
      this._twitchPlayer.play();

      if (this._player.pauseVideo)
      {
        this._player.pauseVideo();
      }

    });
    this._twitchPlayer.addEventListener(Twitch.Player.OFFLINE, () => {
      console.log('CHANNEL OFFLINE');
      this.twitchReady = false;
      this._twitchPlayer.pause();
    });
  }

  toggleDisplayVideo() {
    try {
      if (this.videoPreviewHidden) {
        if (this._player.pauseVideo) {
          this._player.pauseVideo();
        }
        if (this._twitchPlayer.play) {
          this._twitchPlayer.play();
        }
      } else {
        if (this._player.stopVideo) {
          this._player.stopVideo();
        }
        if (this._twitchPlayer.pause) {
          this._twitchPlayer.pause();
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.videoPreviewHidden = !this.videoPreviewHidden;
  }

  public createSnowflakes(snowflakes: number) {
    return new Array(snowflakes);
  }

  public toggleSnow() {
    this.snowEnabled = !this.snowEnabled;
    localStorage.setItem('snowEnabled', this.snowEnabled ? 'true' : 'false');
  }

  logout() {
    location.href = '/api/account/logout';
  }

  public toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }

}
